import tw, { css } from 'twin.macro'

export const base = {
  container: tw`max-w-[230px] p-0`,
  textWrapper: tw`w-full p-0 m-0`,
}

export const leadership = {
  image: tw`object-cover h-[230px] w-[230px] rounded-3xl`,
  heading: tw`p-0 mb-[-5px] text-base`,
  role: tw`uppercase text-purple text-xs`,
  icon: tw`h-4 w-4 ml-2 inline-block`,
}

export const administrative = {
  heading: tw`text-black p-0 mb-[-5px] text-base font-proxima font-bold`,
  role: tw`uppercase text-xs pt-2 `,
}

export const board = {
  container: tw`flex pb-5 phablet:flex-col`,
  textWrapper: tw`w-1/3 pr-10 phablet:w-full`,
  heading: tw`text-lg`,
  members: tw`my-2 p-0 font-bold text-base font-proxima`,
  grid: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  `,
  table: tw`w-2/3 border-b border-purple/20 pb-5 phablet:w-full`,
}

export const teams = {
  wrapper: tw`pb-16 px-4`,
  heading: tw`mb-10 text-xl`,
  grid: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 30px;
  `,
  table: tw`w-2/3 border-b-2 border-purple pb-8 phablet:w-full`,
}
