import React from 'react'
import tw, { css, styled } from 'twin.macro'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ArrowLongRightIcon } from '@heroicons/react/24/solid'
import { Link } from 'gatsby'
import { teams, leadership, administrative, board, base } from './Teams.styles.js'
import Wysiwyg from '../../components/Wysiwyg'
import Container from '../../components/Container/Container'
import Button from '../../components/Button'

const Excerpt = styled(Wysiwyg)`
  ${tw`leading-6 text-sm my-3`}
  p {
    ${tw`leading-7 text-sm my-3`}
  }
`

const LeadershipTile = ({ title, image, content, role, link }) => {
  const imageObject = getImage(image?.gatsbyImage)
  const excerpt = `${content?.substring(0, 109)}...`
  return (
    <Container css={base.container}>
      <div tw="mb-3">
        {image && imageObject && (
          <GatsbyImage alt={image?.altText} image={imageObject} css={leadership.image} imgStyle={{ borderRadius: `1.5rem` }} />
        )}
      </div>
      <div css={(base.textWrapper, tw`flex flex-col min-h-[205px] justify-between`)}>
        <div>
          <h2 css={leadership.heading}>
            {title && (
              <Button href={link} variant="text">
                {title}
              </Button>
            )}
          </h2>
          <span css={leadership.role}>{role}</span>
          {content && <Excerpt>{excerpt}</Excerpt>}
        </div>
        {link && (
          <Button href={link} variant="text" iconPosition="after" iconLibrary="solid">
            <span tw="text-sm">
              Read More
              <ArrowLongRightIcon css={leadership.icon} />
            </span>
          </Button>
        )}
      </div>
    </Container>
  )
}

const AdministrativeTile = ({ title, role }) => (
  <Container>
    <h3 css={administrative.heading}>{title}</h3>
    <p css={administrative.role}>{role}</p>
  </Container>
)

const BoardComponent = ({ members, position }) => (
  <Container css={board.container}>
    <div css={board.textWrapper}>
      <h2 css={board.heading}>{position}</h2>
    </div>
    <div css={[board.grid, board.table]}>
      {members.map(m => {
        if (m.content) {
          return (
            <Button
              variant="text"
              href={m.uri}
              key={m.id}
              css={board.members}
              iconLibrary="solid"
              icon="ArrowLongRightIcon"
              iconStyle={leadership.icon}
              iconPosition="after"
            >
              {m.title}
            </Button>
          )
        }
        return (
          <p key={m.id} css={board.members} tw="text-black">
            {m.title}
          </p>
        )
      })}
    </div>
  </Container>
)

const Teams = ({ title, team, boardMembers, variant }) => (
  <Container variant="wide" css={teams.wrapper}>
    <h2 css={teams.heading}>{title}</h2>
    {variant === 'Administrative' || variant === 'Leadership' ? (
      <div css={teams.grid}>
        {team.map(t => {
          if (variant === 'Administrative') {
            if (t.content) {
              return (
                <Link to={t.uri} tw="text-black hover:text-purple">
                  <AdministrativeTile key={t.id} title={t.title} role={t.team.role} />{' '}
                </Link>
              )
            }
            return <AdministrativeTile key={t.id} title={t.title} role={t.team.role} />
          }
          return (
            <LeadershipTile
              key={t.id}
              title={t.title}
              role={t.team.role}
              content={t.content}
              image={t.featuredImage && t.featuredImage.node}
              link={t.content && t.uri}
            />
          )
        })}
      </div>
    ) : (
      <>
        {boardMembers.map((m, index) => (
          <BoardComponent key={index} members={m.member} position={m.position} />
        ))}
      </>
    )}
  </Container>
)

LeadershipTile.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  role: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.string,
}

AdministrativeTile.propTypes = {
  title: PropTypes.string.isRequired,
  role: PropTypes.string,
}

BoardComponent.propTypes = {
  members: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.node]),
  position: PropTypes.string.isRequired,
}

Teams.propTypes = {
  title: PropTypes.string.isRequired,
  team: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.node]),
  boardMembers: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.node]),
  variant: PropTypes.oneOf(['Administrative', 'Board', 'Leadership']),
}

export default Teams
